import React from 'react'
import SbEditable from 'storyblok-react'

import Header from './../components/Header'
import DynamicComponent from './../components/DynamicComponent'
import Seo from './../components/Seo'

class StoryblokEntry extends React.Component {
	
	static getDerivedStateFromProps(props, state) {
		
		if (state.story.uuid === props.pageContext.story.uuid) {
			return null
		}

		return StoryblokEntry.prepareStory(props)

	}

	static prepareStory(props) {

		const story = Object.assign({}, props.pageContext.story)
		const settings = Object.assign({}, props.pageContext.settings)

		story.content = JSON.parse(story.content)
		settings.content = JSON.parse(settings.content)
		
		return { story, settings }

	}

	constructor(props) {

		super(props)

		this.state = StoryblokEntry.prepareStory(props)

	}

	render() {

		let story = this.state.story
		let content = this.state.story.content
		let settings = this.state.settings.content

		return (
			<SbEditable content={content}>
				<Seo 
					title={content.seo_title || `${story.name} | Alex Schoemaker`}
					description={content.seo_description}
					keywords={content.seo_keywords}
					image={content.seo_image ? content.seo_image.filename : ''}
				/>
				<Header settings={settings} />
				<DynamicComponent blok={content} settings={settings} />
			</SbEditable>
		)
	}
}

export default StoryblokEntry
